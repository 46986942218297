import { Component, OnInit, Input } from '@angular/core';
import { Distribution } from '../distribution';
import { Simulation } from '../simulation';
import { SpecieService } from 'src/app/species/specie.service';
import { SpecieAbsorption } from 'src/app/species/specie-absorption';
import { SpecieCo2 } from 'src/app/species/specie-co2-absorption';
import { DistributionService } from '../distribution.service';
import { SimulationService } from '../simulation.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Inventory } from '../inventory';
import { Specie } from 'src/app/species/specie';
import { tap } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { Range } from 'src/app/ranges/ranges'; 
import { SpecieAggregator } from 'src/app/species/specie-aggregator';
import { Zone } from '../zone';
import { ReportDownloaderComponent } from '../report-downloader/report-downloader.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RangesService } from 'src/app/ranges/ranges.service';
import { any } from '@uirouter/core';

@Component({
  selector: 'app-space-simulation',
  templateUrl: './space-simulation.component.html',
  styleUrls: ['./space-simulation.component.scss']
})
export class SpaceSimulationComponent implements OnInit {
  @Input() space;
  @Input() zones: Zone[];

  selectedZone: Zone;

  allRanges: { [key: string]: [string, number[], boolean] } = {}
  //This is field is to know what parameters to show the checkbox for
  null_filters: {[key: string]: boolean}
  currentDistribution: Distribution[] = [];
  currentTotal: SpecieAbsorption;
  simulatedTotal: SpecieAbsorption;

  co2CurrentTotal: SpecieCo2;
  co2SimulatedTotal: SpecieCo2;

  simulation: Simulation;
  availableSimulations: Simulation[] = [];
  aggregatedSpecies: SpecieAggregator[] = [];
  allSpecies: SpecieAggregator[] = [];
  availableSpecies: SpecieAggregator[];
  orderedByAbsorption: SpecieAggregator[];
  orderedByConfort: SpecieAggregator [];
  orderedByCo2: SpecieAggregator[];
  simulationInventory: any[] = [];
  selectedSimulations: number[] = [];
  dataView = 0;
  visualization = 0;
  distributionLoaded = false;
  public collapseFilters = false;
  currentSection = 'archive';

  frameSlots = {
    ALI_VIA: 49,
    ALI_PRQ: 25,
    BOS_PRQ: 4,
    SET_TOD: 3,
    MAZ_PRQ: 2
  };
  slots: number;

  qualityTargets = [
    {value: false, target: 'co_absorption', label: 'Absorción de CO'},
    {value: false, target: 'no_absorption', label: 'Absorción de NO'},
    {value: false, target: 'no2_absorption', label: 'Absorción de NO', subindex: '2'},
    {value: false, target: 'so2_absorption', label: 'Absorción de SO', subindex: '2'},
    {value: false, target: 'pm1_absorption', label: 'Absorción de PM', subindex: '1'},
    {value: false, target: 'pm2_5_absorption', label: 'Absorción de PM', subindex: '2,5'},
    {value: false, target: 'pm10_absorption', label: 'Absorción de PM', subindex: '10'},
  ];

  co2Targets = [
    {value: false, target: 'co2_absorption', label: 'Absorción de CO2', subindex: '2'},
    {value: false, target: 'co2_5_years_projection', label: 'Almacenamiento en 5 años CO', subindex: '2'},
    {value: false, target: 'co2_10_years_projection', label: 'Almacenamiento en 10 años CO', subindex: '2'},
    {value: false, target: 'co2_15_years_projection', label: 'Almacenamiento en 15 años CO', subindex: '2'},
    {value: false, target: 'co2_20_years_projection', label: 'Almacenamiento en 20 años CO', subindex: '2'},
    {value: false, target: 'co2_25_years_projection', label: 'Almacenamiento en 25 años CO', subindex: '2'},
    {value: false, target: 'co2_30_years_projection', label: 'Almacenamiento en 30 años CO', subindex: '2'},
    {value: false, target: 'co2_35_years_projection', label: 'Almacenamiento en 35 años CO', subindex: '2'},
    {value: false, target: 'co2_40_years_projection', label: 'Almacenamiento en 40 años CO', subindex: '2'}
  ];

  confortTargets = [
    {value: false, target: 'heat_reduction', label: 'Reducción Tª del aire bajo dosel'},
    {value: false, target: 'heat_reduction_pavement', label: 'Reducción Tª de la acera'},
    {value: false, target: 'heat_reduction_asphalt', label: 'Reducción Tª del asfalto'},
    {value: false, target: 'heat_reduction_grass', label: 'Reducción Tª del césped'},
    {value: false, target: 'heat_reduction_pipeclay', label: 'Reducción Tª del albero'},
    {value: false, target: 'uva_reduction', label: 'Reduc. rayos UVA (400-315 nm)'},
    {value: false, target: 'uvbc_reduction', label: 'Reduc. rayos UV(B+C) (315-100 nm)'},
    {value: false, target: 'out_radiation_reduction_pavement_pct', label: 'Reducción radiación de salida en acera'},
    {value: false, target: 'out_radiation_reduction_asphalt_pct', label: 'Reducción radiación de salida enasfalto'},
    {value: false, target: 'out_radiation_reduction_grass_pct', label: 'Reducción radiación de salida en césped'},
    {value: false, target: 'out_radiation_reduction_pipeclay_pct', label: 'Reducción radiación de salida en albero'},
    {value: false, target: 'radiation_pavement', label: 'Reducción Tª radiante en acera'},
    {value: false, target: 'radiation_asphalt', label: 'Reducción Tª radiante en asfalto'},
    {value: false, target: 'radiation_grass', label: 'Reducción Tª radiante en césped'},
    {value: false, target: 'radiation_pipeclay', label: 'Reducción Tª radiante en albero'}
  ];

  constructor(
    private speciesService: SpecieService,
    private distributionService: DistributionService,
    private simulationService: SimulationService,
    private modalService: NgbModal,
    private authService: AuthService,
    private rangesService: RangesService
  ) { }

  ngOnInit() {
    this.simulation = new Simulation();
    this.availableSpecies = [];
    this.orderedByAbsorption = [];
    this.orderedByConfort = [];
    this.orderedByCo2 = [];
    this.allRanges = this.rangesService.getRangesDict()
  }
  resetZone() {
    this.simulation = new Simulation();
    this.selectedZone = undefined;
  }
  resetSimulation() {
    this.simulation = new Simulation();
  }
  selectZone() {
    this.loadSimulations();
    this.simulation = new Simulation();
    this.simulation.zone = this.selectedZone.id;
    this.simulation.author = this.authService.user.id;
    this.simulation.distribution = [];
    this.allRanges = this.rangesService.getRangesDict()
    this.null_filters = this.fillNullDictionary()
    this.speciesService.listCompleteSpecies().subscribe((speciesList: SpecieAggregator[]) => {
      this.simulation.filters.qualityFilters.species = speciesList;
      this.simulation.filters.bioFilters.species = speciesList;
      this.allSpecies = speciesList;

    });
    this.speciesService.listSpeciesRange().subscribe((speciesList: SpecieAggregator[]) => {
      this.aggregatedSpecies = speciesList;

      this.filterSpecies();
    });

    this.loadCurrentDistribution();
    if (this.space.useful_surface === undefined || this.space.plantation_frame === undefined) {
      this.slots = 100;
    } else {
      this.slots = Math.round(this.space.useful_surface / this.frameSlots[this.space.plantation_frame]);
    }
  }

  fillNullDictionary(){
    let myDictionary: { [key: string]: boolean } = {};
    for (let key in this.allRanges){
      myDictionary[key] = true
    }
    return myDictionary
  }

  changeValueOfAdmittedNull(parameter: string){
    this.null_filters[parameter] = !this.null_filters[parameter]
    this.filterSpecies()
  }

  filterSpecies() {
    this.availableSpecies.splice(0);
    let index = 0
    this.aggregatedSpecies.forEach((spec: SpecieAggregator) => {
      if (this.simulation.filters.shouldInclude(spec, this.null_filters)) {
          this.availableSpecies.push(this.allSpecies[index]);
        }
      index = index + 1
    });
    this.orderSpecies();
  }
  orderSpecies() {
    this.orderedByAbsorption = this.availableSpecies.map(obj => ({...obj}));
    let orderByTargets = false;
    this.qualityTargets.forEach(target => orderByTargets = orderByTargets || target.value);
    if (orderByTargets) {
      this.orderedByAbsorption.sort((a, b) => {
        let sumA = 0;
        let sumB = 0;
        this.qualityTargets.forEach(qv => {
          if (qv.value) {
            if (qv.target === 'co2_absorption') {
              sumA += a.airQuality[qv.target];
              sumB += b.airQuality[qv.target];
            } else {
              sumA += a.airQuality[qv.target] * a.specie.leaf_area;
              sumB += b.airQuality[qv.target] * b.specie.leaf_area;
            }
          }
        });
        sumA *= this.getSpecieMultiplier(a.specie);
        sumB *= this.getSpecieMultiplier(b.specie);
        return sumB - sumA;
      });
    } else {
      this.orderedByAbsorption.sort((a, b) => (a.specie.science_name < b.specie.science_name ? -1 : 1));
    }
    this.orderedByConfort = this.availableSpecies.map(obj => ({...obj}));
    orderByTargets = false;
    this.confortTargets.forEach(target => orderByTargets = orderByTargets || target.value);
    if (orderByTargets) {
      this.orderedByConfort.sort((a, b) => {
        let sumA = 0;
        let sumB = 0;
        this.confortTargets.forEach(qv => {
          if (qv.value) {
            sumA += a.thermalComfort[qv.target];
            sumB += b.thermalComfort[qv.target];
          }
        });
        return sumB - sumA;
      });

    } else {
      this.orderedByConfort.sort((a, b) => (a.specie.science_name < b.specie.science_name ? -1 : 1));
    }

    this.orderedByCo2 = this.availableSpecies.map(obj => ({...obj}));
    orderByTargets = false;
    this.co2Targets.forEach(target => orderByTargets = orderByTargets || target.value);
    if (orderByTargets) {
      this.orderedByCo2.sort((a, b) => {
        let sumA = 0;
        let sumB = 0;
        this.co2Targets.forEach(qv => {
          if (qv.value) {
            sumA += a.Co2[qv.target];
            sumB += b.Co2[qv.target];
          }
        });
        return sumB - sumA;
      });

    } else {
      this.orderedByCo2.sort((a, b) => (a.specie.science_name < b.specie.science_name ? -1 : 1));
    }
  }
  /**
   * Recupera la distribución de especies del espacio.
   */
  loadCurrentDistribution() {
    this.distributionLoaded = false;
    this.currentDistribution.splice(0);
    this.simulation.distribution.splice(0);
    this.distributionService.loadZoneDistribution(this.selectedZone.id).subscribe(
      zoneDistribution => {
        zoneDistribution.forEach(specieDistribution => {
          this.distributionService.hidrateDistribution(specieDistribution).subscribe(
            (distribution: Distribution) => this.currentDistribution.push(distribution)
          );
          this.distributionService.hidrateDistribution(specieDistribution).subscribe(
            (simulated: Distribution) => {
              simulated.id = undefined;
              simulated.inventory.forEach((simulatedInventory: Inventory) => {
                simulatedInventory.id = undefined;
                simulatedInventory.distribution = undefined;
              });
              this.simulation.distribution.push(simulated);
            });
        });

        this.distributionLoaded = true;
        this.sortResult();
        this.currentTotal = this.calculateDistTotal(this.currentDistribution);
        this.simulatedTotal = this.calculateDistTotal(this.simulation.distribution);
        this.co2CurrentTotal = this.calculateCo2Total(this.currentDistribution);
        this.co2SimulatedTotal = this.calculateCo2Total(this.simulation.distribution);

    });
  }

  check_if_parameter(parameter_name: string){
    return this.allRanges[parameter_name][2]
  }

  distributionSum(parameter: string): number {
    let sum = 0;
    this.currentDistribution.forEach(item => sum += item[parameter]);
    return sum;
  }
  simulationSum(parameter: string): number {
    let sum = 0;
    this.simulation.distribution.forEach(item => sum += item[parameter]);
    return sum;
  }
  variationSum(parameter: string): number {
    return this.simulationSum(parameter) - this.distributionSum(parameter);
  }
  getCurrentDist(distribution) {
    for (const dist of this.currentDistribution) {
      if (dist.specie.id === distribution.specie.id) {
        return dist;
      }
    }
  }

  /**
   * Calcula la variación del parametro parameter para la especie.
   */
  simulationVariation(distrib: Distribution, parameter: string) {
    let current = 0;
    this.currentDistribution.forEach((distribution: Distribution) => {
      if (distrib.specie.id === distribution.specie.id) {
        current = distribution[parameter];
      }
    });
    return distrib[parameter] - current;
  }
  addSpecie(specie: Specie) {
    let found = false;
    this.simulation.distribution.forEach(specieDistribution => {
      if (specieDistribution.specie.id === specie.id) {
        found = true;
        specieDistribution.addUnit();
      }
      this.simulatedTotal = this.calculateDistTotal(this.simulation.distribution);
      this.co2SimulatedTotal = this.calculateCo2Total(this.simulation.distribution);
      this.sortResult();
    });
    if (!found) {
      const newSpecieDist = new Distribution(this.speciesService);
      newSpecieDist.updateSpecie(specie).subscribe(() => {
        newSpecieDist.addUnit();
        this.simulation.distribution.push(newSpecieDist);
        this.simulatedTotal = this.calculateDistTotal(this.simulation.distribution);
        this.co2SimulatedTotal = this.calculateCo2Total(this.simulation.distribution);
        this.sortResult();
      });
    }
  }
  remSpecie(specie: Specie) {
    let shouldRemove;
    this.simulation.distribution.forEach((specieDistribution, index) => {
      if (specieDistribution.specie.id === specie.id) {
        specieDistribution.popUnit();
        if (specieDistribution.amount < 1) {
          shouldRemove = index;
        }
      }
    });
    if (shouldRemove !== undefined) {
      this.simulation.distribution.splice(shouldRemove, 1);
    }
    this.simulatedTotal = this.calculateDistTotal(this.simulation.distribution);
    this.co2SimulatedTotal = this.calculateCo2Total(this.simulation.distribution);
    this.sortResult();
  }
  remSpecieUnit(specieUnit: Inventory) {
    let shouldRemove;
    this.simulation.distribution.forEach((specieDistribution, distributionIndex) =>
      specieDistribution.inventory.forEach((inventoryLine, index) => {
        if (inventoryLine === specieUnit) {
            specieDistribution.popUnit(index);
            if (specieDistribution.amount < 1) {
              shouldRemove = distributionIndex;
            }
        }
      }));
    if (shouldRemove !== undefined) {
      this.simulation.distribution.splice(shouldRemove, 1);
    }
    this.simulatedTotal = this.calculateDistTotal(this.simulation.distribution);
    this.co2SimulatedTotal = this.calculateCo2Total(this.simulation.distribution);
    this.sortResult();
  }
  calculateDistTotal(distrib: Distribution[]): SpecieAbsorption {
    const result = new SpecieAbsorption();
    distrib.forEach(specieDistribution => {
      result.co_absorption += specieDistribution.co_absorption * this.getSpecieMultiplier(specieDistribution.specie);
      result.no_absorption += specieDistribution.no_absorption * this.getSpecieMultiplier(specieDistribution.specie);
      result.no2_absorption += specieDistribution.no2_absorption * this.getSpecieMultiplier(specieDistribution.specie);
      result.so2_absorption += specieDistribution.so2_absorption * this.getSpecieMultiplier(specieDistribution.specie);
      result.pm1_absorption += specieDistribution.pm1_absorption * this.getSpecieMultiplier(specieDistribution.specie);
      result.pm2_5_absorption += specieDistribution.pm2_5_absorption * this.getSpecieMultiplier(specieDistribution.specie);
      result.pm10_absorption += specieDistribution.pm10_absorption * this.getSpecieMultiplier(specieDistribution.specie);
      result.cov_emission += specieDistribution.cov_emission * specieDistribution.specie.biomass_gr;
    });
    return result;
  }

  calculateCo2Total(distrib: Distribution[]): SpecieCo2 {
    const result = new SpecieCo2(0,0,0,0,0,0,0,0,0,0);
    distrib.forEach(specieDistribution => {
      result.co2_absorption += specieDistribution.co2_absorption;
      result.co2_5_years_projection += specieDistribution.co2_5_years_projection;
      result.co2_10_years_projection += specieDistribution.co2_10_years_projection;
      result.co2_20_years_projection += specieDistribution.co2_20_years_projection;
      result.co2_30_years_projection += specieDistribution.co2_30_years_projection;
      result.co2_40_years_projection += specieDistribution.co2_40_years_projection;
    });
    return result;
  }

  saveSimulation() {
    if (this.simulation.id !== undefined) {
      this.simulationService.updateSimulation(this.simulation).subscribe(
        (simulation: Simulation) => {
          this.simulation.distribution.splice(0);
          simulation.distribution.forEach(distribution => this.distributionService.hidrateDistribution(distribution).subscribe(
            (hidratedDistribution: Distribution) => this.simulation.distribution.push(hidratedDistribution)
          ));
        });
    } else {

      this.simulationService.createSimulation(this.simulation).subscribe(
        async (simulation: Simulation) => {
          await this.hydrateAndProcessDistributions(simulation);
          this.availableSimulations.push(this.simulation);
        }
      );
      this.allRanges = this.rangesService.getRangesDict()
      this.sortResult();

    }
  }

  cloneSimulation() {
    this.simulation.id = undefined;
    this.simulation.distribution.forEach(distribution => {
      distribution.id = undefined;
    });
    this.saveSimulation();
  }
  loadSimulations() {
    this.simulationService.loadSimulations(this.selectedZone.id).subscribe(
      (simulations: Simulation[]) => {
        this.availableSimulations.splice(0);
        simulations.forEach(simulation => {
          const simDist = [];
          const observables = [];
          simulation.distribution.forEach(
            distribution => observables.push(this.distributionService.hidrateDistribution(distribution).pipe(
              tap((hidratedDistribution: Distribution) => simDist.push(hidratedDistribution))
          )));
          forkJoin(observables).subscribe(() => {
            simulation.distribution = simDist;
            this.availableSimulations.push(simulation);
          });
        });
      }
    );
  }
  loadSimulation(simulation) {

    this.simulation = new Simulation();
    Object.assign(this.simulation, simulation);
    this.simulation.filters.qualityFilters.species = this.aggregatedSpecies;
    this.simulation.filters.bioFilters.species = this.aggregatedSpecies;
    this.simulation.filters.comfortFilters.species = this.aggregatedSpecies;
    this.simulation.filters.resilienceFilters.species = this.aggregatedSpecies;
    this.simulation.filters.Co2Filters.species = this.aggregatedSpecies;
    this.allRanges = this.rangesService.getRangesDict()
    this.null_filters = this.fillNullDictionary()
    this.filterSpecies();
    this.sortResult();
    this.changeUnits();
  }

  changeUnits() {
    this.orderSpecies();
    this.currentTotal = this.calculateDistTotal(this.currentDistribution);
    this.simulatedTotal = this.calculateDistTotal(this.simulation.distribution);
    this.co2CurrentTotal = this.calculateCo2Total(this.currentDistribution);
    this.co2SimulatedTotal = this.calculateCo2Total(this.simulation.distribution);
  }
  getSpecieMultiplier(specie: Specie) {
    if (this.simulation.display_units !== 'g/día') {
      if (specie.leaves_kind.toLowerCase() !== 'perenne') {
        return 0.271;
      }
      return 0.365;
    }
    return 1;


  }

  getReport() {
    const modal = this.modalService.open(ReportDownloaderComponent, { size: 'xl' as 'lg' });
    modal.componentInstance.units = this.simulation.display_units.split('/')[0];
    modal.componentInstance.space = this.space;
    modal.componentInstance.selectedSimulation = this.simulation;
    modal.componentInstance.selectedZone = this.selectedZone;
    modal.componentInstance.zones = this.zones;
    modal.componentInstance.download_options = 0; // Distribution and simulation
    modal.result.then(
      () => {},
      () => {}
    );
  }

  getDistributionReport() {
    const modal = this.modalService.open(ReportDownloaderComponent, { size: 'xl' as 'lg' });
    modal.componentInstance.units = this.simulation.display_units.split('/')[0];
    modal.componentInstance.space = this.space;
    modal.componentInstance.selectedSimulation = this.simulation;
    modal.componentInstance.selectedZone = this.selectedZone;
    modal.componentInstance.zones = this.zones;
    modal.componentInstance.download_options = 1; // Only distribution
    modal.result.then(
      () => {},
      () => {}
    );
  }


  sortResult() {
    this.simulation.distribution.sort((a, b) => (a.specie.science_name < b.specie.science_name ? -1 : 1));
    this.currentDistribution.sort((a, b) => (a.specie.science_name < b.specie.science_name ? -1 : 1));
    this.simulationInventory.splice(0);
    this.simulation.distribution.forEach(simulated => {
      this.simulationInventory.push(simulated);
      simulated.inventory.forEach((simulatedInventory: Inventory) => this.simulationInventory.push(simulatedInventory));
    });
  }
  scrollTo(section) {
    document.querySelector('#' + section)
    .scrollIntoView();
  }
  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }
  deleteSimulation(sim: Simulation) {
    this.simulationService.deleteSimulation(sim).subscribe(
      () => this.loadSimulations()
    );
  }


  calculateRangeOfCo2Storage(specie: any) {
    const intervalLength = Math.ceil(this.aggregatedSpecies.length / 5);
    const orderedByProperty = this.aggregatedSpecies
        .map(obj => obj.specie.co2_storage)
        .sort((a, b) => a - b);

    let rangeIndex = -1;
    for (let i = 0; i < 5; i++) {
        const start = i * intervalLength;
        const end = start + intervalLength - 1;
        const rangeStart = orderedByProperty[start];
        const rangeEnd = orderedByProperty[end < orderedByProperty.length ? end : orderedByProperty.length - 1];
        if (specie.airQuality.co2_storage >= rangeStart && specie.airQuality.co2_storage <= rangeEnd) {
            rangeIndex = i;
            break;
        }
    }

    return rangeIndex + 1; 
}

  async hydrateAndProcessDistributions(simulation: Simulation): Promise<void> {
    this.simulation.id = simulation.id;
    this.simulation.distribution.splice(0); // Clean the distributions before the ones associated with zone

    // Aux variable
    const hydratedDistributions: Distribution[] = [];

    for (let distribution of simulation.distribution) {
      try {
        // Hidrate distribution (get it from server)
        const hydratedDistribution = await this.distributionService.hidrateDistribution(distribution).toPromise();
        
        // Push it in aux variable
        hydratedDistributions.push(hydratedDistribution);
      } catch (err) {
        console.error('Error at distribution', err);
      }
    }

    this.simulation.distribution = hydratedDistributions;

    this.availableSimulations.push(this.simulation);
  }


}
